import { FC } from "react";

import { Typography } from "@aviary";
import { ErrorPageWrapper } from "@components";

import ErrorIcon from "../../assets/svgs/404-page-icon.svg";

import * as styles from "./Custom404Page.styles";

const Custom404Page: FC = () => {
  const renderIcon = () => <ErrorIcon css={styles.svg} />;

  const renderText = () => (
    <Typography css={styles.text} isSecondaryWeight>
      The web address may be incorrect or the page may have been moved. If you're not sure, simply
      go to the homepage and find what you are looking for from there.
    </Typography>
  );

  const renderHeading = () => (
    <Typography type="h1" css={styles.title}>
      This page doesn't exist
    </Typography>
  );

  return (
    <>
      <ErrorPageWrapper
        title="404 | This page doesn't exist"
        heading={renderHeading()}
        text={renderText()}
        icon={renderIcon()}
      />
    </>
  );
};

export { Custom404Page };
