import { css } from "@emotion/react";

import { colors } from "@styles";

export const svg = css`
  max-width: min(80%, 20rem);
  margin-inline: auto;
`;

export const text = css`
  color: ${colors.grey.darkFamily.light};
`;

export const title = css`
  color: ${colors.grey.darkFamily.dark};
`;
